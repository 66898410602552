import { BrowserRouter } from 'react-router-dom';

import AppProvider from './hooks';

import Route from './routes';

const App = () => {
  return (
    <BrowserRouter>
      <AppProvider>
        <Route />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
