import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import PageHeader from '../../components/PageHeader';
import Breadcrumb from '../../components/Breadcrumb';
import InputSearch from '../../components/InputSearch';
import Loading from '../../components/Loading';

import api from '../../services/api';
import { useLocation } from '../../hooks/location';

import handleInternalServerError from '../../utils/handleInternalServerError';

const Church = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [churches, setChurches] = useState([]);
    const { location, storage } = useLocation();
    const { state, city, category, churchForSectoredEvent = null } = location

    useEffect(() => {
        async function fetch() {
            try {
                const { data } = await api.get(`cities/${city.id}/category/${category.id}/churches${churchForSectoredEvent ? '?churchId=' + churchForSectoredEvent.id : ''}`)
                setChurches(data);
            } catch (err) {
                if (err.response?.status === 404) {
                    setChurches([{
                        'id': 0,
                        'name': 'Nenhum dado encontrado'
                    }]);
                } else {
                    handleInternalServerError()
                }
            }
            setLoading(false);
        }
        fetch()
    }, [city, category]);

    const handleClick = (church) => {
        if (church.id === 0) return false;
        storage({ church })
        history.push('schedule')
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Selecione uma congregação" />
                <div className="app-wrapper">
                    <Breadcrumb description={<span><b>{category.name}</b> em <b>{city.name}/{state.name}</b></span>} />
                    <InputSearch placeholder="Digite o nome da congregação" />

                    {
                        churches.map((church) => (
                            <Row key={church.id} className="list-btn-link">
                                <Col span={24}>
                                    <Button type="link" block onClick={() => handleClick(church)}>
                                        {church.name}
                                        <RightOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            </>
    )
};

export default Church;