import styled from "styled-components";
import { Input as InputAntd } from "antd";

export const Input = styled(InputAntd)`
    background-color: #FFFFFF;
    font-size: 24px;
    padding: 10px 20px;
    border: none;
    color: #CCD1D9;

    ::-webkit-input-placeholder {
        color: #CCD1D9;
    }
    :-ms-input-placeholder {
        color: #CCD1D9;
    }
    ::placeholder {
        color: #CCD1D9;
    }
    .ant-input {
        color: #434A54;
    }
`;