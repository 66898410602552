import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR'
import { zonedTimeToUtc } from 'date-fns-tz'

import PageHeader from '../../../components/PageHeader';
import Breadcrumb from '../../../components/Breadcrumb';
import CardLink from '../../../components/CardLink';
import Loading from '../../../components/Loading';

import handleInternalServerError from '../../../utils/handleInternalServerError';

import { useLocation } from '../../../hooks/location';
import api from '../../../services/api';

const Schedule = () => {
    const history = useHistory()
    const { location, storage } = useLocation()
    const [services, setServices] = useState(null)
    const [loading, setLoading] = useState(true)
    const { church, category } = location

    useEffect(() => {
        if (!location || !church || !category) {
            history.push('/admin/category')
        }
    }, [history, location, church, category])

    useEffect(() => {
        async function fetch() {
            try {
                const { data } = await api.get(`churches/${church.id}/category/${category.id}/scheduled-services`)
                setServices(data.services)
            } catch (err) {
                if (err?.response?.status === 404) {
                    localStorage.clear()
                    history.push('/login')
                    return false
                }
                handleInternalServerError()
            }
        }
        fetch()
        setLoading(false)
    }, [history, church, category])

    const handleClick = (service) => {
        storage({ service })
        history.push('/admin/capacity-control')
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Selecione um horário" admin />
                <div className="app-wrapper">
                    <Breadcrumb description={<span className="breadcrumb"><b>{category.name}</b> - <b>{church.name}</b></span>} />

                    {
                        services ?
                            services.map(service => (
                                <Row key={service.id}>
                                    <Col span={24}>
                                        <CardLink onClick={() => handleClick(service)}>
                                            <span className="date">{format(zonedTimeToUtc(parseISO(service.datetime), 'America/Sao_Paulo'), "dd 'de' MMMM' às 'HH:mm", { locale: pt })}</span>
                                        </CardLink>
                                    </Col>
                                </Row>
                            )) : null
                    }
                </div>
            </>
    );
}

export default Schedule;