import React from 'react';
import { SearchOutlined } from '@ant-design/icons';

import search from '../../utils/search';

import { Input } from './styles.js';

const InputSearch = ({placeholder, ...rest}) => {
    const handleSearch = (e) => {
        search(e.target.value, document.querySelectorAll('.list-btn-link'))
    }
    return (
        <Input
            className="search-input"
            size="large" 
            placeholder={placeholder}
            {...rest} 
            prefix={<SearchOutlined />} 
            onKeyUp={handleSearch}
        />
    )
}

export default InputSearch;