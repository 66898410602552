import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import PageHeader from '../../../components/PageHeader';
import InputSearch from '../../../components/InputSearch';
import Loading from '../../../components/Loading';

import { useLocation } from '../../../hooks/location';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

import handleInternalServerError from '../../../utils/handleInternalServerError';

const Church = () => {
    const history = useHistory()
    const { location, storage } = useLocation()
    const { category } = location
    const [churches, setChurches] = useState(null)
    const [loading, setLoading] = useState(true)
    const { user } = useAuth()

    useEffect(() => {
        if (!location || !category) {
            history.push('/admin/category')
            return false
        }
    }, [history, location, category])

    useEffect(() => {
        async function fetch() {
            try {
                const { data } = await api.get(`users/${user?.id}/churches?categoryId=${category.id}`, {
                    headers: { Authorization: `Bearer ${user?.access_token}` }
                })
                setChurches(data)
            } catch (err) {
                if (err?.response?.status === 401) {
                    localStorage.clear()
                    history.push('/login')
                    return false
                }
                handleInternalServerError()
            }
        }
        fetch()
        setLoading(false)
    }, [user, history, category])

    const handleClick = (church) => {
        storage({ church })
        history.push('schedule')
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Selecione uma congregação" admin />
                <div className="app-wrapper">
                    <InputSearch placeholder="Digite o nome da congregação" />
                    {
                        churches ?
                            churches.map((church) => (
                                <Row key={church.id} className="list-btn-link">
                                    <Col span={24}>
                                        <Button type="link" block onClick={() => handleClick(church)}>
                                            {church.name}
                                            <RightOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                            )) : null
                    }
                </div>
            </>
    )
};

export default Church;