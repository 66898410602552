import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import PageHeader from '../../components/PageHeader';
import InputSearch from '../../components/InputSearch';
import Loading from '../../components/Loading';

import api from '../../services/api';
import { useLocation } from '../../hooks/location';

import handleInternalServerError from '../../utils/handleInternalServerError';

const City = () => {
    const history = useHistory();
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(true);
    const { location, storage } = useLocation();
    const { state } = location

    useEffect(() => {
        async function fetch() {
            try {
                const { data } = await api.get(`states/${state.id}/cities`);
                if (!data.cities) {
                    throw new Error('No cities')
                }
                setCities(data.cities);
            } catch (err) {
                if (err.response?.status === 404) {
                    setCities([{
                        'id': 0,
                        'name': 'Nenhum dado encontrado'
                    }]);
                } else {
                    handleInternalServerError()
                }
            }
            setLoading(false);
        }
        fetch()
    }, [state, history])

    const handleClick = (city) => {
        if (city.id === 0) return false;
        storage({ city })
        history.push('categories')
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Selecione sua cidade" />
                <div className="app-wrapper">
                    <InputSearch placeholder="Digite o nome da sua cidade" />

                    {
                        cities.map((city) => (
                            <Row key={city.id} className="list-btn-link">
                                <Col span={24}>
                                    <Button type="link" block onClick={() => handleClick(city)}>
                                        {city.name}
                                        <RightOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            </>
    )
};

export default City;