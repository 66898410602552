import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR'

import PageHeader from '../../components/PageHeader';
import Breadcrumb from '../../components/Breadcrumb';
import CardLink from '../../components/CardLink';
import Loading from '../../components/Loading';

import api from '../../services/api';
import { useLocation } from '../../hooks/location';

import handleInternalServerError from '../../utils/handleInternalServerError';

const Schedule = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [churches, setChurches] = useState([]);
    const { location, storage } = useLocation();
    const { church, category, city, state } = location

    useEffect(() => {
        async function fetch() {
            try {
                const { data } = await api.get(`churches/${church.id}/category/${category.id}/scheduled-services`);
                setChurches(data);
            } catch (err) {
                if (err.response?.status === 404) {
                    setChurches(null);
                } else {
                    setChurches(null);
                    handleInternalServerError()
                }
            }
            setLoading(false);
        }
        fetch()
    }, [church, category])

    const handleClick = (service) => {
        delete churches.services;
        storage({ churches, service })
        history.push('available-seats')
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Selecione uma data" />
                <div className="app-wrapper">
                    <Breadcrumb description={<span><b>{category.name}</b> em <b>{city.name}/{state.name}</b> no <b>{church.name}</b></span>} />

                    <Row>
                        <Col span={24}>
                            {
                                churches ?
                                    churches.services.map(service => (
                                        <CardLink onClick={() => handleClick(service)} key={service.id} >
                                            <div className="card-schedule-infos">
                                                <span className="date">{format(parseISO(service.datetime), "dd 'de' MMMM' às 'HH:mm", { locale: pt })}</span>
                                            </div>
                                        </CardLink>
                                    ))
                                    :
                                    <Alert
                                        message="Ops..."
                                        description={`Não há eventos agendados para os próximos dias`}
                                        type="error"
                                        showIcon
                                        style={{ marginInline: 10 }}
                                    />
                            }
                        </Col>
                    </Row>
                </div>
            </>
    );
}

export default Schedule;