import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import App from './App';
import ptBR from 'antd/lib/locale/pt_BR';

import 'antd/dist/antd.css';
import './styles/main.scss';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ptBR} >
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
