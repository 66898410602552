const search = (filter, list) => {
    let value;
    list.forEach((item) => {
        value = item.getElementsByTagName("span")[0].textContent.toUpperCase();
        if (value.indexOf(filter.toUpperCase()) > -1) {
            item.style.display = ''
        } else {
            item.style.display = 'none'
        }
    })
}

export default search;