import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Modal, Radio, Button, Select, InputNumber } from 'antd';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR'
import MaskedInput from 'antd-mask-input';
import ReCAPTCHA from 'react-google-recaptcha';

import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import { useLocation } from '../../hooks/location';
import CpfIsValid from '../../utils/CpfIsValid';
import api from '../../services/api';

import './index.scss';

const Booking = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const { location, storage } = useLocation();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [listChurches, setListChurches] = useState([]);
    const recaptchaRef = useRef()
    const { service, churches, seats, category } = location

    useEffect(() => {
        setLoading(false)
        getchurches()
    }, [churches])

    const getchurches = async () => {
        const { data } = await api.get('churches')
        console.log(data)
        setListChurches(data.map(s => ({ value: s.id, label: s.name })))
    }

    const modalAlert = (content) => {
        Modal.warning({ title: 'Atenção', content, maskClosable: false })
    }

    const handleFinishBookingClick = async () => {
        const { cpf, church_side, age, my_church } = form.getFieldsValue()
        if (!cpf || !church_side || !age || !my_church) {
            modalAlert('Preencha todos os campos para finalizar a reserva')
            return
        }

        if (!CpfIsValid(cpf)) {
            modalAlert('CPF inválido')
            return
        }

        try {
            await recaptchaRef.current.executeAsync()
            recaptchaRef.current.reset()

            setButtonLoading(true)
            const { data } = await api.post(`bookings`, {
                scheduledService: { id: service.scheduledServices[0].id },
                seat: { id: church_side },
                cpf: cpf.replace(/[^\d]+/g, ''),
                church: { id: my_church },
                age
            })

            if (Object.keys(data).length === 0) {
                let seatName = seats.find(seat => (seat.id === church_side)).seatType.name
                modalAlert(`Não há mais lugares disponíveis para ${seatName}`)
                return
            }
            setButtonLoading(false)
            storage({ booking: data, seats })
            history.push('booking-summary')
        } catch (err) {
            setButtonLoading(false)

            if (err?.response?.data?.message === 'The cpf already has a booking') {
                Modal.error({
                    title: 'Já existe uma reserva para este CPF'
                })
                return
            }
            Modal.error({
                title: 'Erro!',
                content: 'Erro ao realizar sua reserva'
            })
        }
    }

    const selectPropsChuches = {
        onChange: () => console.log(),
        style: { width: '100%' },
        options: listChurches
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Reservar lugar" />
                <div className="app-wrapper wrapper-avaliable-seats">
                    <div className="booking-event-title">
                        <h1>{category.name}</h1>
                        <p> <strong>Data e hora:</strong> {format(parseISO(service.datetime), "dd 'de' MMMM' às 'HH:mm", { locale: pt })}</p>
                        <p><strong>Local:</strong> {churches.name}</p>
                    </div>

                    <Card>
                        <div className="booking-card-content">
                            <h1>Informações para reserva</h1>
                            <Form
                                layout="vertical"
                                form={form}
                            >
                                <Form.Item label="Insira o seu CPF" name="cpf">
                                    <MaskedInput mask="111.111.111-11" autoComplete="off" />
                                </Form.Item>
                                <span className="cpf-alert">Utilizaremos o CPF apenas para geração do código da sua reserva, o mesmo não será armazenado.</span>

                                <Form.Item label="Selecione sua comum congregação" name="my_church">
                                    <Select className="church-select" {...selectPropsChuches} />
                                </Form.Item>

                                <Form.Item label="Idade" name="age">
                                    <InputNumber min={1} max={12} placeholder='Ex.: 4' style={{ width: '20%' }} />
                                </Form.Item>

                                <Form.Item label="Escolha um lado" name="church_side">
                                    <Radio.Group className="custom-radio-button">
                                        {
                                            seats.map(seat => {
                                                if (seat.unoccupied > 0) {
                                                    return <Radio.Button key={seat.id} value={seat.id}>{seat.seatType.name}</Radio.Button>
                                                } else {
                                                    return <Radio.Button disabled key={seat.id} value={seat.id}>{seat.seatType.name}</Radio.Button>
                                                }
                                            })
                                        }
                                    </Radio.Group>
                                </Form.Item>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey='6LefELEbAAAAAJQR0034zlAq6pFfn--R1AHUIgpK'
                                />
                            </Form>
                        </div>

                    </Card>

                    <div className="booking-button-container">
                        <Button type="primary" shape="round" onClick={handleFinishBookingClick} loading={buttonLoading}>Finalizar reserva</Button>
                    </div>
                </div>
            </>
    );
}

export default Booking;