import React, { createContext, useCallback, useState, useContext } from 'react'

import api from '../services/api'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [data, setData] = useState(() => {
        const authenticatedUser = localStorage.getItem('@CCBReservar:authenticatedUser')

        if (authenticatedUser) {
            return { authenticatedUser }
        }

        return null
    })

    const signIn = useCallback(async ({ username, password }) => {
        const response = await api.post('auth/login', { username, password })

        const { login, access_token, id } = response.data

        localStorage.setItem('@CCBReservar:authenticatedUser', { login, access_token, id })

        setData({ login, access_token, id })
    }, [])

    const signOut = useCallback(() => {
        localStorage.removeItem('@CCBReservar:authenticatedUser')

        setData(null)
    }, [])

    return (
        <AuthContext.Provider value={{ user: data, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }

    return context
}

export default AuthContext