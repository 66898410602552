import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Button } from 'antd';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR'

import './index.scss';

import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';

import { useLocation } from '../../hooks/location';
import api from '../../services/api';

import handleInternalServerError from '../../utils/handleInternalServerError';

const Cancellation = () => {
    const history = useHistory()
    const { location } = useLocation()
    const [loading, setLoading] = useState(true)
    const [seat, setSeat] = useState(null)
    const [buttonLoading, setButtonLoading] = useState(false)
    const { category, booking, service, church, seats } = location
    const { seat: seatAux } = booking

    useEffect(() => {
        if (!location || !booking) {
            history.push('')
            return false
        }
        setSeat(seats.find(seat => seat.id === seatAux.id))
        setLoading(false)
    }, [history, location, booking, seats, seatAux])

    const handleCancellationClick = async () => {
        try {
            setButtonLoading(true)
            await api.delete(`bookings/${booking.id}`)
            setButtonLoading(false)
            history.push({ pathname: 'canceled', state: { flag: true } })
        } catch (err) {
            handleInternalServerError()
        }
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Cancelar reserva" />
                <div className="app-wrapper">
                    <Card className="custom-card">
                        <div className="obs-cancellation">
                            <h3>Observações</h3>

                            <ul>
                                <li>Ao cancelar a reserva seu lugar será disponibilizado para que outro irmão ou irmã o ocupe.</li>
                                <li>Após o cancelamento não será possível reverter a sua reserva.</li>
                                <li>Não será garantido que haverá lugares disponíveis após o cancelamento da reserva.</li>
                            </ul>
                        </div>
                        <div className="booking-info">
                            <h2>{category.name}</h2>
                            <p>{format(parseISO(service.datetime), "dd 'de' MMMM' às 'HH:mm", { locale: pt })} no {church.name}</p>
                            <p>Código da reserva: <span className="booking-code">{booking.simpleCode}</span></p>
                            <p>
                                {
                                    seat?.seatType.name.split(' ').length === 1 ?
                                        `Lado: ${seat?.seatType.name.toLowerCase()}` :
                                        seat?.seatType.name
                                }
                            </p>
                        </div>
                    </Card>

                    <div className="btn-container">
                        <Button type="primary" danger shape="round" onClick={handleCancellationClick} loading={buttonLoading}>Cancelar Reserva</Button>
                    </div>
                </div>
            </>
    );
}

export default Cancellation;