import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader as Header } from 'antd';
import { ArrowLeftOutlined, PoweroffOutlined } from '@ant-design/icons';

import { useAuth } from '../../hooks/auth';

import './index.scss';

const PageHeader = ({ title, onback = null, icon = <ArrowLeftOutlined />, admin = false, ...rest }) => {
    const history = useHistory();
    const { signOut } = useAuth();

    const handleLogOut = () => {
        signOut()
        history.push('/login')
    }

    return (
        <Header
            className={admin ? 'site-page-header header-admin' : 'site-page-header'}
            onBack={onback ? onback : () => history.goBack()}
            title={title}
            backIcon={icon}
            style={{
                backgroundColor: '#043D60',
            }}
            extra={
                admin ? <PoweroffOutlined className="ant-page-header-back-button" style={{ fontSize: 18 }} onClick={handleLogOut} /> : null
            }
        />
    );
}

export default PageHeader;