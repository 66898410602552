import React, { useState } from 'react'
import { Table, Button, Modal } from 'antd'
import { ExclamationOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

import PageHeader from '../../../../components/PageHeader'
import api from '../../../../services/api'

import ChuchAccessModal from './ChurchAccessModal'

const { confirm } = Modal

const Church = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showDeleteConfirm = (user) => {
        confirm({
            title: `Tem certeza que deseja deletar`,
            icon: <ExclamationOutlined />,
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                console.log('ola', user) //endpoint para excluir usuários
            }
        })
    }

    const dataSource = [
        { key: '1', church: 'Jardim Bela Vista', state: 'São Paulo', city: 'Bauru' },
        { key: '2', church: 'Jaragua', state: 'São Paulo', city: 'Bauru' },
    ];

    const columns = [
        { title: 'Igreja', dataIndex: 'church', key: 'church' },
        { title: 'Cidade', dataIndex: 'city', key: 'city' },
        { title: 'Estado', dataIndex: 'state', key: 'state' },
        {
            title: 'Ações',
            dataIndex: '',
            key: 'x',
            render: (user) => (
                <Button onClick={(user) => showDeleteConfirm(user)}>
                    <MinusCircleOutlined />
                    <span>Remover</span>
                </Button>
            ),
        }
    ];

    return (
        <>
            <PageHeader title="Igrejas do usuário" admin/>
            <div className="app-wrapper">
                <h1>Igrejas que o usuário tem acesso</h1>
                <Button type="primary" shape="round" onClick={() => setIsModalVisible(true)}>
                    <PlusCircleOutlined />
                    <span>Adicionar</span>
                </Button>
                <div className="space-30" />
                <Table dataSource={dataSource} columns={columns} />
                <ChuchAccessModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}/>
            </div>
        </>
    )
}

export default Church