import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const PageNotFound = () => {
    const history = useHistory()

    useEffect(() => {
        history.push('')
        return false
    })

    return (null)
}

export default PageNotFound