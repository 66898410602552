import React from 'react'
import {
    Route as ReactDOMRoute,
    Redirect,
} from 'react-router-dom'

import { useAuth } from '../hooks/auth'

const PrivateRoute = ({
    component: Component,
    ...rest
}) => {
    const { user } = useAuth()

    return (
        <ReactDOMRoute
            {...rest}
            render={({ location }) => {
                return !!user ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location }
                        }}
                    />
                )
            }}
        />
    )
}

export default PrivateRoute