import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const HealthAlert = () => {
    return (
        <div className="health-alert border-distance">
            <ExclamationCircleOutlined className="health-alert-icon"/>
            <p>Lembre-se de seguir as recomendações das autoridades sanitárias locais.</p>
        </div>
    );
}

export default HealthAlert;