import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { ExclamationOutlined } from '@ant-design/icons';

import Card from '../../Card'

const CheckInRegistered = ({ booking }) => {
    const history = useHistory()

    return (
        <>
            <Card>
                <div className="is-valid-container">
                    <div className="space-30" />
                    <div className="custom-icon-card-container">
                        <ExclamationOutlined className="custom-icon-card exclamationIcon" />
                    </div>
                    <div className="space-30" />
                    <h1 className="booking-warning">Entrada já registrada</h1>
                    <p><strong>Já existe uma entrada registrada para este CPF</strong></p>
                    <div className="space-30" />
                    <span className="booking-code-title">Código</span>
                    <h2 className="booking-code">
                        {
                            booking.simpleCode.substring(0, 2) + ' ' +
                            booking.simpleCode.substring(2, 4) + ' ' +
                            booking.simpleCode.substring(4, 6) + ' ' +
                            booking.simpleCode.substring(6, 8)
                        }
                    </h2>
                    <span className="seat-type">Lado: {booking.seat.seatType.name}</span>
                    <div className="space-30" />
                </div>
            </Card>

            <div className="btn-container">
                <Button type="primary" shape="round" onClick={() => history.goBack()}>Consultar próxima</Button>
            </div>
        </>
    )
}

export default CheckInRegistered