import React from 'react';
import { RightOutlined } from '@ant-design/icons';

import { Card } from './styles';

const CardLink = ({ children, onClick }) => {
    return (
        <Card onClick={onClick} style={{ cursor: 'pointer' }}>
            <div className="card-list-content">
                {children}
            </div>
            <div>
                <RightOutlined />
            </div>
        </Card>
    );
}

export default CardLink;