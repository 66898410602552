import styled from "styled-components";

export const CustomBreadcrumbs = styled.div`
    width: 100%;
    min-height: 50px;
    background-color: #E6ECF0;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0.5rem 1rem;
    color: #043D60;
`;