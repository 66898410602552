import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { useAuth } from '../../hooks/auth';

import Card from '../../components/Card';
import PageHeader from '../../components/PageHeader';

import handleInternalServerError from '../../utils/handleInternalServerError';

const Login = () => {
    const history = useHistory()
    const [form] = Form.useForm();
    const { signIn, user } = useAuth();

    useEffect(() => {
        if (!!user) {
            history.push('admin')
        }
    }, [user, history])

    const handleSignIn = async () => {
        const { username, password } = form.getFieldsValue()

        if (!username || !password) {
            Modal.warning({ title: 'Usuário e senha são obrigatórios' })
            return false
        }

        try {
            await signIn({ username, password })
            history.push('admin')
        } catch (err) {
            if (err.response?.status === 401) {
                Modal.error({ title: 'Usuário ou senha incorretos' })
                return false
            }
            handleInternalServerError()
        }
    }

    const handleEnterKey = (e => {
        if (e.keyCode === 13) {
            handleSignIn()
        }
    })

    return (
        <>
            <PageHeader onback={() => history.push('')} title="Login" />
            <div className="app-wrapper">
                <Card>
                    <div className="space-30" />
                    <div className="login-form">
                        <Form
                            layout="vertical"
                            form={form}
                        >
                            <Form.Item name="username">
                                <Input autoComplete="off" prefix={<UserOutlined />} placeholder="Usuário" />
                            </Form.Item>

                            <Form.Item name="password">
                                <Input.Password prefix={<LockOutlined />} onKeyUp={(e) => handleEnterKey(e)} type="password" placeholder="Senha" />
                            </Form.Item>
                        </Form>
                    </div>
                </Card>

                <div className="btn-container">
                    <Button type="primary" shape="round" onClick={handleSignIn}>Acessar</Button>
                </div>
            </div>
        </>
    );
}

export default Login;