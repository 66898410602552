import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Button } from 'antd';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { CheckOutlined, CloseOutlined, ExclamationOutlined } from '@ant-design/icons';

import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import AddressContainer from '../../components/AddressContainer';
import HealthAlert from '../../components/HealthAlert';
import Loading from '../../components/Loading';

import api from '../../services/api';
import { useLocation } from '../../hooks/location';

import handleInternalServerError from '../../utils/handleInternalServerError';

import './index.scss';

const AvailableSeat = () => {
    const history = useHistory();
    const [hasAvailableSeat, setHasAvailableSeat] = useState(true)
    const [isAvailableToBooking, setIsAvailableToBooking] = useState(true)
    const [seats, setSeats] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { location, storage } = useLocation();
    const [loading, setLoading] = useState(true);
    const [dateCaseIsnotAvailableToBooking, setSateCaseIsnotAvailableToBooking] = useState('')
    const { service, churches, category, city, state } = location
    const { address } = churches

    const fetch = useCallback(async () => {
        try {
            const { data } = await api.get(`scheduled-services/${service.scheduledServices[0].id}/seats`)

            const checkIfhasAvailableSeats = data.seats.find(seat => seat.unoccupied !== 0);

            setHasAvailableSeat(!!checkIfhasAvailableSeats)

            setIsAvailableToBooking(data.booking)

            setSateCaseIsnotAvailableToBooking(format(parseISO(data.startBooking), "dd'/'MM' às 'HH:mm", { locale: pt }))

            setSeats(data.seats)
        } catch (err) {
            handleInternalServerError()
        }
    }, [service])

    useEffect(() => {
        async function loadData() {
            await fetch()
            setLoading(false)
        }
        loadData()
    }, [fetch])

    const handleBookClick = () => {
        storage({ seats })
        history.push('booking')
    }

    const handleUpdateClick = async () => {
        setButtonLoading(true)
        await fetch()
        setButtonLoading(false)
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title={churches.name} />
                <div className="app-wrapper wrapper-avaliable-seats">
                    <div className="booking-event-title">
                        <h1>{category.name}</h1>
                        <p><strong>Data e hora:</strong> {format(parseISO(service.datetime), "dd 'de' MMMM' às 'HH:mm", { locale: pt })}</p>
                        <p><strong>Local:</strong> {churches.name}</p>
                    </div>

                    <div className="space-30"></div>

                    <div className="custom-icon-card-container">
                        {
                            hasAvailableSeat && isAvailableToBooking ?
                                <CheckOutlined className="custom-icon-card checkIcon" /> :
                                isAvailableToBooking ?
                                    <CloseOutlined className="custom-icon-card errorIcon" /> : <ExclamationOutlined className="custom-icon-card exclamationIcon" />
                        }
                    </div>

                    <Card>

                        {
                            hasAvailableSeat && isAvailableToBooking ?
                                <h1 className="avaliable-title">Lugares disponíveis</h1>
                                : null
                        }

                        {
                            hasAvailableSeat && isAvailableToBooking ?
                                <Row className="seats">
                                    {
                                        seats.map(seat => (
                                            <Col span={seat.seatType.name.includes('Músicos') ? 24 : 12} className="seat-avaliable" key={seat.id}>
                                                <h1 className={seat.unoccupied ? 'seat-available' : 'seat-unavailable'}>{seat.unoccupied}</h1>
                                                <span>{seat.seatType.name.toLowerCase()}</span>
                                            </Col>
                                        ))
                                    }
                                </Row> : isAvailableToBooking ?
                                    <Row>
                                        <h1 className="avaliable-title">Não há mais lugares disponíveis nesta congregação</h1>
                                    </Row> :
                                    <Row>
                                        <h1 className="avaliable-title">Os lugares estarão disponíveis para reserva a partir do dia {dateCaseIsnotAvailableToBooking}</h1>
                                    </Row>
                        }
                        {isAvailableToBooking ?
                            <Button type="default" shape="round" onClick={handleUpdateClick} loading={buttonLoading}>Atualizar</Button> : null
                        }
                    </Card>

                    {
                        hasAvailableSeat && isAvailableToBooking ?
                            <div className="booking-button-container">
                                <Button type="primary" shape="round" onClick={handleBookClick} className="booking-button">Reservar</Button>
                            </div> : <div className="space-30" />
                    }

                    <HealthAlert />

                    <AddressContainer
                        streetAndNumber={`${address.street}, ${address.number}`}
                        neighborAndCity={`${address.neighborhood}, ${city.name}/${state.name}`}
                        lat={address.latitude}
                        long={address.longitude}
                    />
                </div>
            </>
    );
}

export default AvailableSeat;