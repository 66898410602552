import React, { createContext, useCallback, useState, useContext } from 'react'

const LocationContext = createContext()

export const LocationProvider = ({ children }) => {
    const [data, setData] = useState(() => {
        const location = localStorage.getItem('@CCBReservar:location')

        if (location) {
            return JSON.parse(location)
        }

        return null
    })

    const storage = useCallback(async (value) => {
        const localStorageValue = localStorage.getItem('@CCBReservar:location');

        if (localStorageValue) {
            let newLocalStorage = Object.assign(JSON.parse(localStorageValue), value)
            localStorage.setItem('@CCBReservar:location', JSON.stringify(newLocalStorage))
        } else {
            localStorage.setItem('@CCBReservar:location', JSON.stringify(value))
        }

        setData(JSON.parse(localStorage.getItem('@CCBReservar:location')))
    }, [])


    return (
        <LocationContext.Provider value={{ location: data, storage }}>
            {children}
        </LocationContext.Provider>
    )
}

export function useLocation() {
    const context = useContext(LocationContext);

    if (!context) {
        throw new Error('useLocation must be used within an LocationProvider')
    }

    return context
}

export default LocationContext