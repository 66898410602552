import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import PageHeader from '../../components/PageHeader';
import Breadcrumb from '../../components/Breadcrumb';
import Loading from '../../components/Loading';

import api from '../../services/api';
import { useLocation } from '../../hooks/location';

import handleInternalServerError from '../../utils/handleInternalServerError';

const Category = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const { location, storage } = useLocation();
    const { state, city } = location

    useEffect(() => {
        async function fetch() {
            try {
                const { data } = await api.get(`cities/${city.id}/categories`)
                setCategories(data)
            } catch (err) {
                if (err.response?.status === 404) {
                    setCategories([{
                        'id': 0,
                        'name': 'Nenhum dado encontrado'
                    }])
                } else {
                    handleInternalServerError()
                }
            }
            setLoading(false);
        }
        fetch()
    }, [city]);

    const handleClick = (category) => {
        if (category.id === 0) return false;
        storage({ category })
        history.push(category?.sectored ? 'sectored-event' : 'churches')
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Selecione uma categoria" />
                <div className="app-wrapper">
                    <Breadcrumb description={<span>Localização selecionada <b>{city.name}/{state.name}</b></span>} />

                    {
                        categories.map(category => (
                            <Row key={category.id} className="list-btn-link">
                                <Col span={24}>
                                    <Button type="link" block onClick={() => handleClick(category)}>
                                        {category.name}
                                        <RightOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            </>
    )
};

export default Category;