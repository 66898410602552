import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import { format, parseISO } from 'date-fns';
import { Button } from 'antd';
import pt from 'date-fns/locale/pt-BR'
import QRcode from 'qrcode.react'

import Card from '../../components/Card';
import PageHeader from '../../components/PageHeader';
import HealthAlert from '../../components/HealthAlert';
import AddressContainer from '../../components/AddressContainer';
import Loading from '../../components/Loading';

import { useLocation } from '../../hooks/location';

import './index.scss';

const BookingSummary = () => {
    const history = useHistory();
    const { location } = useLocation();
    const [loading, setLoading] = useState(true);
    const [seat, setSeat] = useState(null)
    const [qr, setQr] = useState('qr')
    const {
        service = null,
        category = null,
        city = null,
        state = null,
        churches = null,
        booking = null,
        seats = null,
        hiddenButton = false } = location || {}
    const { address = null } = churches || {}
    const checkInAlreadyRegistered = booking?.status === 5

    useEffect(() => {
        if (!location || !booking) {
            history.push('/state')
            return false;
        }
        setSeat(seats.find(seat => seat.id === booking.seat.id))
        setQr(booking.simpleCode)
        setLoading(false)
    }, [seats, booking, history, location])

    const handleCancellationClick = () => {
        history.push('cancellation')
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Resumo da reserva" />
                <div className="app-wrapper">
                    <div className="space-30"></div>

                    <div className="custom-icon-card-container">
                        {
                            checkInAlreadyRegistered ?
                                <ExclamationOutlined className="custom-icon-card exclamationIcon" /> :
                                <CheckOutlined className="custom-icon-card checkIcon" />
                        }
                    </div>
                    <Card>
                        <div className="booking-summary-content">
                            <h1 className={`booking-status ${checkInAlreadyRegistered ? 'booking-warning' : null}`}>
                                {checkInAlreadyRegistered ? 'Entrada já registrada' : 'Reservado'}
                            </h1>
                            {
                                checkInAlreadyRegistered ? null : <p>O seu lugar foi reservado com sucesso!</p>
                            }

                            <div className="booking-info">
                                <QRcode
                                    id="myqr"
                                    value={qr}
                                    size={240}
                                    includeMargin={true}
                                />
                                <h2 className="booking-code">{
                                    booking.simpleCode.substring(0, 2) + ' ' +
                                    booking.simpleCode.substring(2, 4) + ' ' +
                                    booking.simpleCode.substring(4, 6) + ' ' +
                                    booking.simpleCode.substring(6, 8)
                                }</h2>

                                <span className="seat-category">
                                    {
                                        seat?.seatType.name.split(' ').length === 1 ?
                                            `Lado: ${seat?.seatType.name.toLowerCase()}` :
                                            seat?.seatType.name.toLowerCase()
                                    }
                                </span>

                                <h3>Apresente este código ao chegar na Congregação escolhida</h3>
                            </div>
                        </div>
                    </Card>

                    <Card>
                        <div className="summary">
                            <h2>{category.name}</h2>
                            <p> <strong>Data e hora:</strong> {format(parseISO(service.datetime), "dd 'de' MMMM' às 'HH:mm", { locale: pt })}</p>
                            <p><strong>Local:</strong> {churches.name}</p>
                            <AddressContainer
                                streetAndNumber={`${address.street}, ${address.number}`}
                                neighborAndCity={`${address.neighborhood}, ${city.name}/${state.name}`}
                                lat={address.latitude}
                                long={address.longitude}
                            />
                        </div>
                    </Card>

                    <HealthAlert />

                    <div className="bottom-btns-action btns-action-booking-summary">
                        {!hiddenButton ?
                            <Button type="primary" shape="round" onClick={() => history.push('/state')}>Fazer outra reserva</Button>
                            : null
                        }
                        {
                            !checkInAlreadyRegistered ?
                                <Button type="primary" danger shape="round" onClick={handleCancellationClick}>Cancelar reserva</Button>
                                : null
                        }
                    </div>
                </div>
            </>
    );
}

export default BookingSummary;