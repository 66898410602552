import React, { useState } from 'react';
import { Form, Button, Radio, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import MaskedInput from 'antd-mask-input';

import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';

import api from '../../services/api';
import { useLocation } from '../../hooks/location';

import handleInternalServerError from '../../utils/handleInternalServerError';

import './index.scss';

const BookingSearch = () => {
    const [form] = Form.useForm()
    const [searchFor, setSearchFor] = useState('CPF')
    const history = useHistory()
    const { storage } = useLocation()

    const handleBookingSearchClick = async () => {
        const { type, field } = form.getFieldsValue()

        if (!field) {
            Modal.warning({ title: `O campo ${searchFor} é obrigatório` })
            return false
        }

        let value = field;
        if (!type || type === 'cpf') {
            value = field.replace(/[^\d]+/g, '')
        }

        if (type === 'code') {
            value = field.replace(/\s/g, '').replace(/_/g, '')
            if (value.length !== 8) {
                Modal.warning({ title: 'Código inválido' })
                return false
            }
        }

        try {
            const { data } = await api.get(`bookings?type=${type || 'cpf'}&value=${value}`)
            storage({ booking: data })
            history.push('my-booking')
        } catch (err) {
            if (err?.response?.status === 404) {
                Modal.error({
                    title: `Não existe nenhuma reserva com este ${searchFor}`
                })
            } else {
                handleInternalServerError()
            }
        }
    }

    const handleChangeType = (e) => {
        form.setFieldsValue({ field: '' })
        setSearchFor(e.target.value === 'cpf' ? 'CPF' : 'Código')
    }

    return (
        <>
            <PageHeader title="Minhas reservas" />
            <div className="app-wrapper">
                <Card>
                    <Form
                        layout="vertical"
                        form={form}
                    >
                        <Form.Item label="Buscar por" name="type">
                            <Radio.Group
                                className="custom-radio-button"
                                onChange={handleChangeType}
                                defaultValue="cpf"
                            >
                                <Radio.Button value="cpf">CPF</Radio.Button>
                                <Radio.Button value="code">Código</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item label={`Insira o seu ${searchFor}`} name="field">
                            {
                                searchFor === 'CPF' ? <MaskedInput mask="111.111.111-11" autoComplete="off" /> : <MaskedInput mask="** ** ** **" autoComplete="off" />
                            }
                        </Form.Item>
                    </Form>
                </Card>

                <div className="btn-container">
                    <Button type="primary" shape="round" onClick={handleBookingSearchClick}>Buscar reserva</Button>
                </div>
            </div>
        </>
    );
}

export default BookingSearch;