import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import { useLocation } from '../../../hooks/location';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';

import handleInternalServerError from '../../../utils/handleInternalServerError';

const Category = () => {
    const history = useHistory()
    const [categories, setCategories] = useState(null)
    const [loading, setLoading] = useState(true)
    const { user } = useAuth()
    const { storage } = useLocation()

    useEffect(() => {
        async function fetch() {
            try {
                const { data } = await api.get(`users/${user?.id}/categories`, {
                    headers: { Authorization: `Bearer ${user?.access_token}` }
                })
                setCategories(data)
            } catch (err) {
                if (err?.response?.status === 401) {
                    localStorage.clear()
                    history.push('/login')
                    return false
                }
                if (err?.response?.status === 404) {
                    setCategories([{
                        'id': 0,
                        'name': 'Nenhum dado encontrado'
                    }])
                }
                handleInternalServerError()
            }
        }
        fetch()
        setLoading(false)
    }, [user, history])

    const handleClick = (category) => {
        storage({ category })
        history.push('church')
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Selecione uma categoria" admin />
                <div className="app-wrapper">
                    {
                        categories ?
                            categories.map((category) => (
                                <Row key={category.id} className="list-btn-link">
                                    <Col span={24}>
                                        <Button type="link" block onClick={() => handleClick(category)}>
                                            {category.name}
                                            <RightOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                            )) : null
                    }
                </div>
            </>
    )
};

export default Category;