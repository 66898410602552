import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './Route';

import Home from '../pages/Home';
import State from '../pages/State';
import City from '../pages/City';
import Category from '../pages/Category';
import SectoredEvent from '../pages/SectoredEvent';
import Church from '../pages/Church';
import Schedule from '../pages/Schedule';
import AvailableSeat from '../pages/AvailableSeat';
import Book from '../pages/Booking';
import BookingSummary from '../pages/BookingSummary';
import Cancellation from '../pages/Cancellation';
import Canceled from '../pages/Canceled';
import BookingSearch from '../pages/BookingSearch';
import MyBooking from '../pages/MyBooking';
import Login from '../pages/Login';

import Administration from '../pages/admin/Administration';
import AdminChurch from '../pages/admin/Church';
import AdminCategory from '../pages/admin/Category';
import AdminSchedule from '../pages/admin/Schedule';
import AdminCapacityControl from '../pages/admin/CapacityControl';
import AdminBookingValidation from '../pages/admin/BookingValidation';
import SearchedBooking from '../pages/admin/SearchedBooking';
import Users from '../pages/admin/Users';
import FormUsers from '../pages/admin/Users/Form';
import ChurchUsers from '../pages/admin/Users/Church';

import PageNotFound from '../pages/NotFound';

const Routes = () => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/state" component={State} />
        <Route exact path="/cities" component={City} />
        <Route exact path="/categories" component={Category} />

        <Route exact path="/sectored-event" component={SectoredEvent} />

        <Route exact path="/churches" component={Church} />
        <Route exact path="/schedule" component={Schedule} />
        <Route exact path="/available-seats" component={AvailableSeat} />
        <Route exact path="/booking" component={Book} />
        <Route exact path="/booking-summary" component={BookingSummary} />
        <Route exact path="/cancellation" component={Cancellation} />
        <Route exact path="/canceled" component={Canceled} />
        <Route exact path="/booking-search" component={BookingSearch} />
        <Route exact path="/my-booking" component={MyBooking} />

        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/admin" component={Administration} />
        <PrivateRoute exact path="/admin/users" component={Users} />
        <PrivateRoute exact path="/admin/users/form" component={FormUsers} />
        <PrivateRoute exact path="/admin/users/church" component={ChurchUsers} />
        <PrivateRoute exact path="/admin/church" component={AdminChurch} />
        <PrivateRoute exact path="/admin/category" component={AdminCategory} />
        <PrivateRoute exact path="/admin/schedule" component={AdminSchedule} />
        <PrivateRoute exact path="/admin/capacity-control" component={AdminCapacityControl} />
        <PrivateRoute exact path="/admin/booking-validation" component={AdminBookingValidation} />
        <PrivateRoute exact path="/admin/searched-booking" component={SearchedBooking} />

        <Route exact path="*" component={PageNotFound} />
    </Switch>
);

export default Routes;