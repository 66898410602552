import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';

import './index.scss';

const Canceled = () => {
    const { state } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (!state) {
            history.push('')
            return
        }
    }, [state, history])

    return (
        <>
            <PageHeader onback={() => history.push('')} title="Reserva Cancelada" icon={<CloseOutlined />} />
            <div className="app-wrapper">
                <div className="card-canceled">
                    <div className="custom-icon-card-container">
                        <CheckOutlined className="custom-icon-card checkIcon" />
                    </div>
                    <Card >
                        <h1>Reserva Cancelada</h1>
                        <p>Sua reserva foi cancelada. Seu lugar será disponibilizado para outro irmão ou irmã.</p>

                        <Button type="default" shape="round" onClick={() => history.push('')}>Fechar</Button>
                    </Card>
                </div>
            </div>

        </>
    );
}

export default Canceled;