import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import PageHeader from '../../components/PageHeader';
import InputSearch from '../../components/InputSearch';
import Loading from '../../components/Loading';

import api from '../../services/api';
import { useLocation } from '../../hooks/location';

import handleInternalServerError from '../../utils/handleInternalServerError';

const State = () => {
    const history = useHistory();
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(true);
    const { storage } = useLocation()

    useEffect(() => {
        async function fetch() {
            try {
                const { data } = await api.get('states')
                setStates(data)
            } catch (err) {
                setStates([{
                    'id': 0,
                    'name': 'Nenhum estado encontrado'
                }]);
                handleInternalServerError()
            }
            setLoading(false);
        }
        fetch()
    }, []);

    const handleClick = (state) => {
        if (state.id === 0) return false;
        storage({ state })
        history.push('cities')
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Selecione seu estado" onback={() => history.push('')} />
                <div className="app-wrapper">
                    <InputSearch placeholder="Digite o estado desejado" />
                    {
                        states.map((state) => (
                            <Row key={state.id} className="list-btn-link">
                                <Col span={24}>
                                    <Button type="link" block onClick={() => handleClick(state)}>
                                        {state.name}
                                        <RightOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            </>
    )
};

export default State;