import styled from 'styled-components';
import { Card as CardAntd } from 'antd';

export const Card = styled(CardAntd)`
    margin: 20px;
    border-radius: 5px;

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;