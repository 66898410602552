import React from 'react';

import { CustomBreadcrumbs } from './styles';

const Breadcrumb = ({description}) => {
    return (
        <CustomBreadcrumbs>
            {description}
        </CustomBreadcrumbs>
    );
}

export default Breadcrumb;