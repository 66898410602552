import React, { useState, useEffect } from 'react'
import { Button, Col, Row } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import PageHeader from '../../components/PageHeader'
import Breadcrumb from '../../components/Breadcrumb'
import InputSearch from '../../components/InputSearch'
import Loading from '../../components/Loading'

import api from '../../services/api'
import { useLocation } from '../../hooks/location';

const SectoredEvent = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [churches, setChurches] = useState([])
    const { location, storage } = useLocation();
    const { state, city, category } = location

    const loadChurches = async () => {
        try {
            // verificar com Thiago se já existe alguma endpoint que traga as localidades de acordo a cidade escolhida
            const { data } = await api.get('churches')
            setChurches(data)
        } catch (err) {
            console.log(err)
        }
        setLoading(false)
    }

    useEffect(() => {
        loadChurches()
    }, [])

    const handleClick = (church) => {
        storage({ churchForSectoredEvent: church })
        history.push('churches')
    }
    return (
        loading ? <Loading /> :
        <>
            <PageHeader title="Escolha sua comum congregação" />
            <div className="app-wrapper">
                <Breadcrumb description={<span><b>{category.name}</b> em <b>{city.name}/{state.name}</b></span>} />
                <InputSearch placeholder="Digite o nome da sua comum congregação"/>

                {
                        churches.map((church) => (
                            <Row key={church.id} className="list-btn-link">
                                <Col span={24}>
                                    <Button type="link" block onClick={() => handleClick(church)}>
                                        {church.name}
                                        <RightOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        ))
                    }
            </div>
        </>
    )
}

export default SectoredEvent