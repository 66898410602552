import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Select, Table, Button } from 'antd'
import { ExclamationOutlined, MinusCircleOutlined } from '@ant-design/icons'
import handleInternalServerError from '../../../../../utils/handleInternalServerError'
import api from '../../../../../services/api'

const { confirm } = Modal

const ChuchAccessModal = ({isModalVisible, setIsModalVisible}) => {
    const [state, setState] = useState([])
    const [city, setCity] = useState([])
    const [church, setChurch] = useState([])

    const [selectedState, setSelectedState] = useState([])
    const [selectedCity, setSelectedCity] = useState([])

    const [dataSourceNewChurches, setDataSourceNewChurches] = useState([])

    const getStates = async () => {
        try {
            const { data } = await api.get('states')
            const states = data.map(s => ({ value: s.id, label: s.name }))
            setState(states)
        } catch (err) {
            setState([{
                'value': 0,
                'label': 'Nenhum estado encontrado'
            }]);
            handleInternalServerError()
        }
    }

    const getCities = async (stateId) => {
        try {
            const { data } = await api.get(`states/${stateId}/cities`);
            const cities = data.cities.map(c => ({ value: c.id, label: c.name }))
            setCity(cities)
        } catch (err) {
            if (err.response?.status === 404) {
                setCity([{
                    'value': 0,
                    'label': 'Nenhum dado encontrado'
                }]);
            } else {
                handleInternalServerError()
            }
        }
    }

    const getChurches = async (cityId) => {
        try {
            const { data } = await api.get('churches')
            const churches = data.map(s => ({ value: s.id, label: s.name }))
            setChurch(churches)
        } catch (err) {
            setChurch([{
                'value': 0,
                'label': 'Nenhum estado encontrado'
            }]);
            handleInternalServerError()
        }
    }

    useEffect(() => {
        getStates()
    }, [])

    const showDeleteConfirm = (church) => {
        confirm({
            title: `Tem certeza que deseja deletar`,
            icon: <ExclamationOutlined />,
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk: () => {
                console.log(church)
                console.log(dataSourceNewChurches)
                let index = dataSourceNewChurches.findIndex(
                    text => text.key === church.key
                )
                let churches = dataSourceNewChurches.splice(index, 1)
                setDataSourceNewChurches(churches)
            }
        })
    }

    const handleChangeState = (stateId) => {
        setSelectedState(stateId)
        getCities(stateId)
    }

    const handleChangeCity = (cityId) => {
        setSelectedCity(cityId)
        getChurches(cityId)
    }

    const handleChangeChurch = (churchId) => {
        const estado = state.find(stt => ( stt.value === selectedState))
        const cidade = city.find(ct => ( ct.value === selectedCity))
        const igreja = church.find(ch => ( ch.value === churchId))

        setDataSourceNewChurches([...dataSourceNewChurches, {
            key: igreja.value,
            church: igreja.label,
            state: estado.label,
            city: cidade.label
        }])
        console.log(dataSourceNewChurches)
    }

    const selectPropsState = {
        onChange: (stateId) => handleChangeState(stateId),
        style:{ width: '100%' },
        options: state,
    }

    const selectPropsCity = {
        onChange: (cityId) => handleChangeCity(cityId),
        style:{ width: '100%' },
        options: city,
    }

    const selectPropsChurch = {
        onChange: (churchId) => handleChangeChurch(churchId),
        style:{ width: '100%' },
        options: church,
    }

    const columns = [
        { title: 'Igreja', dataIndex: 'church', key: 'church' },
        { title: 'Cidade', dataIndex: 'city', key: 'city' },
        { title: 'Estado', dataIndex: 'state', key: 'state' },
        {
            title: 'Ações',
            dataIndex: '',
            key: 'x',
            render: (_, record) => (
                <Button onClick={() => showDeleteConfirm(record)}>
                    <MinusCircleOutlined />
                    <span>Remover</span>
                </Button>
            ),
        }
    ];

    return (
        <Modal 
            title="Dar acesso a novas igrejas" 
            okText="Salvar"
            visible={isModalVisible}
            onOk={() => alert('ok')}
            onCancel={() => setIsModalVisible(false)}
            width={1000}
        >
            <Row gutter={16}>
                <Col span={8}>
                    <Select {...selectPropsState}/>
                </Col>

                <Col span={8}>
                    <Select {...selectPropsCity}/>
                </Col>
                <Col span={8}>
                    <Select {...selectPropsChurch}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="space-30" />
                    <Table dataSource={dataSourceNewChurches} columns={columns} />
                </Col>
            </Row>
        </Modal>
    )
}

export default ChuchAccessModal