import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import './index.scss'

const Home = () => {
    const history = useHistory();

    useEffect(() => {
        localStorage.removeItem('@CCBReservar:location')
    }, [])

    return (
        <div className="screen">
            <div className="home-header logo">
                <h1>Congregação <br></br>
                    Cristã no Brasil</h1>
                <span>Administração Bauru</span>
            </div>

            <div className="home-body">
                <Button type="default" shape="round" onClick={() => history.push('state')}>Reservar Lugar</Button>
                <Button type="default" shape="round" onClick={() => history.push('booking-search')}>Minhas Reservas</Button>
                <Button className="adm-btn" type="default" shape="round" onClick={() => history.push('login')}>Administração</Button>
            </div>

            <div className="home-footer">
                <p className="app-version">Versão 1.0.0</p>
            </div>
        </div>
    );
}

export default Home;