import React from 'react';
import { Card as AntdCard } from 'antd';
import { CheckOutlined, CloseCircleFilled } from '@ant-design/icons';

import { CardStatus } from './styles';

const Card = ({ children, checked = false, error = false, styles = null, ...rest }) => {
    return (
        <>
            {
                !checked && !error ? null :
                <CardStatus>
                    {checked ?
                        
                        <div className="icon-shape">
                            <CheckOutlined className="checkIcon" />
                        </div>
                        
                    : null}
                    {error ? <CloseCircleFilled className="errorIcon"/> : null}
                </CardStatus>
            }
        
            <AntdCard className="custom-card" styles={styles} {...rest}>
                {children}
            </AntdCard>
        </>
    );
}

export default Card;