import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal } from 'antd'
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR'
import { useHistory } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';

import Card from '../../Card'
import AddressContainer from '../../AddressContainer'
import Loading from '../../Loading'

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';

import handleInternalServerError from '../../../utils/handleInternalServerError';

const ValidBooking = ({ booking }) => {
    const [checkIsSameChurch, setCheckIsSameChurch] = useState(false)
    const [service, setService] = useState(null)
    const [loading, setLoading] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [registerButtonLoading, setRegisterButtonLoading] = useState(false)
    const history = useHistory()
    const { user } = useAuth()

    useEffect(() => {
        setCheckIsSameChurch(booking.scheduledServiceId === booking.seat.scheduledService.id)
        setService(booking.seat.scheduledService.service)
        setLoading(false)
    }, [booking])

    const handleCheckInClick = async () => {
        setRegisterButtonLoading(true)
        try {
            const { status } = await api.post(`bookings/${booking.id}/register`, {
                scheduledService: { id: booking.scheduledServiceId }
            }, {
                headers: { Authorization: `Bearer ${user?.access_token}` }
            })
            setRegisterButtonLoading(false)
            if (status === 201) {
                Modal.success({
                    title: 'Entrada Registrada',
                    okText: 'Consultar próxima',
                    afterClose: () => history.goBack()
                })
            }
        } catch (err) {
            if (err?.response?.status === 401) {
                localStorage.clear()
                history.push('/login')
                return false
            }
            if (err?.response?.status === 400) {
                Modal.error({ title: 'Não há mais lugares disponíveis.', afterClose: () => history.goBack() })
                return false
            }
            handleInternalServerError()
        }
    }

    const handleCancelClick = async () => {
        try {
            setButtonLoading(true)
            await api.delete(`bookings/${booking.id}`)
            setButtonLoading(false)
            Modal.success({
                title: 'Reserva cancelada',
                okText: 'Consultar próxima',
                afterClose: () => history.goBack()
            })
        } catch (err) {
            handleInternalServerError()
            setButtonLoading(false)
        }
    }

    const openCancelModal = () => {
        Modal.confirm({
            title: 'Tem certeza que deseja cancelar esta reserva?',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: handleCancelClick
        })
    }

    return (
        loading ? <Loading /> :
            <>
                <Card>
                    <div className="is-valid-container">
                        <div className="space-30" />
                        <div className="custom-icon-card-container">
                            <CheckOutlined className="custom-icon-card checkIcon" />
                        </div>
                        <h1 className="booking-valid">Reserva válida</h1>
                        <span className="seat-type">Lados: {booking.seat.seatType.name}</span>
                        <div className="space-30" />
                        <span className="seat-type">Código</span>
                        <h2 className="booking-code">{
                            booking.simpleCode.substring(0, 2) + ' ' +
                            booking.simpleCode.substring(2, 4) + ' ' +
                            booking.simpleCode.substring(4, 6) + ' ' +
                            booking.simpleCode.substring(6, 8)
                        }</h2>
                        <div className="space-30" />

                        {
                            checkIsSameChurch ?
                                <div className="btn-area">
                                    <Button className="btn-check-in" shape="round" onClick={handleCheckInClick} loading={registerButtonLoading}>Registrar entrada</Button>
                                    <Button className="btn-check-out" shape="round" onClick={openCancelModal} loading={buttonLoading}>Cancelar reserva</Button>
                                </div> :
                                <Alert message="A reserva foi realizada em outra localidade. Veja o endereço abaixo." type="warning" />
                        }
                    </div>
                </Card>

                <Card>
                    <div className="summary">
                        <h1>Local da reserva</h1>
                        <h2>{service.category.name}</h2>
                        <p> <strong>Data e hora: </strong>{format(parseISO(service.datetime), "dd 'de' MMMM' às 'HH:mm", { locale: pt })}</p>
                        <p><strong>Local:</strong> {service.church.name}</p>
                        <AddressContainer
                            streetAndNumber={`${service.church.address.street}, ${service.church.address.number}`}
                            neighborAndCity={`${service.church.address.neighborhood}, ${service.church.city.name}/${service.church.city.state.name}`}
                            lat={service.church.address.latitude}
                            long={service.church.address.longitude}
                        />
                    </div>
                </Card>

                <div className="space-30" />
            </>
    )
}

export default ValidBooking