import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import PageHeader from '../../../components/PageHeader';
import CardList from '../../../components/CardList';
import Loading from '../../../components/Loading';

import handleInternalServerError from '../../../utils/handleInternalServerError';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useLocation } from '../../../hooks/location';

import './index.scss';

const CapacityControl = () => {
    const history = useHistory();
    const { location, storage } = useLocation()
    const [seats, setSeats] = useState(null)
    const [scheduledService, setScheduledService] = useState(null)
    const [loading, setLoading] = useState(true)
    const [availablePlace, setAvailablePlace] = useState(0)
    const [reservedPlace, setReservedPlace] = useState(0)
    const [registered, setRegistered] = useState(0)
    const [checkLoading, setCheckLoading] = useState(false)
    const { user } = useAuth()
    const { service } = location

    const loadCapacityData = useCallback(async () => {
        const { data } = await api.get(`scheduled-services/${service.scheduledServices[0].id}/seats`)

        let availablePlace = 0
        let reservedPlace = 0
        let registered = 0
        let checkOuts = 0

        data.seats.map(seat => {
            availablePlace = availablePlace + seat.unoccupied
            reservedPlace = reservedPlace + seat.bookingsCount
            registered = registered + seat.checkInsCount
            checkOuts = checkOuts + seat.checkOutsCount
            return true
        })
        setAvailablePlace(availablePlace)
        setReservedPlace(reservedPlace)
        setRegistered(registered - checkOuts)
        setSeats(data.seats)
        setScheduledService(data)
    }, [service])

    useEffect(() => {
        async function fetch() {
            try {
                if (!location) return
                loadCapacityData()
            } catch (err) {
                if (err?.response?.status === 404) {
                    localStorage.clear()
                    history.push('/login')
                    return false
                }
                handleInternalServerError()
            }
        }
        fetch()
        setLoading(false)
    }, [history, location, loadCapacityData])

    const handleBookingValidationClick = () => {
        storage({ scheduledService })
        history.push('booking-validation');
    }

    const handleClick = async (e, seatId, control) => {
        e.preventDefault()
        try {
            setCheckLoading(true)
            const { status } = await api.post(`${control}`, {
                scheduledService: { id: scheduledService.id },
                seat: { id: seatId }
            }, {
                headers: { Authorization: `Bearer ${user?.access_token}` }
            })

            await new Promise(r => setTimeout(r, 1000));

            if (status === 201) {
                loadCapacityData()
            }
            setCheckLoading(false)
        } catch (err) {
            if (err?.response?.status === 400) {
                if (control === 'check-ins') {
                    Modal.error({ title: 'Não há mais lugares disponíveis' })
                }
                if (control === 'check-outs') {
                    Modal.error({ title: 'Não há entradas registradas' })
                }
                setCheckLoading(false)
                return false
            }
            handleInternalServerError()
        }
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Controle de capacidade" admin />
                <div className="app-wrapper">
                    <Spin indicator={<LoadingOutlined />} spinning={checkLoading} size="large">
                        <Row>
                            <Col className="occupation-status" span={24}>
                                <span className="unoccupied"><strong>{availablePlace}</strong> Lugares disponíveis</span>
                                <span className="not-confirmed"><strong>{reservedPlace}</strong> Reservas</span>
                                <span className="not-confirmed"><strong>{registered}</strong> Entradas registradas</span>
                            </Col>
                        </Row>

                        <div className="space-30" />

                        <Row className="page-action-btns">
                            <Col span={24}>
                                <Button shape="round" type="primary" onClick={handleBookingValidationClick}>Validar reserva</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                {
                                    seats ?
                                        seats.map(seat => (
                                            <CardList key={seat.id}>
                                                <Row>
                                                    <Col className="card-title" span={12}><span>{seat.seatType.name}</span></Col>
                                                    <Col className="card-seat-avaliable" span={12}>
                                                        <span>
                                                            Disponíveis <strong>{seat.unoccupied}</strong> / Entradas <strong>{seat.checkInsCount - seat.checkOutsCount}</strong>
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="card-control-btn" span={12}>
                                                        <Button className="btn-check-out" shape="round" onClick={(e) => handleClick(e, seat.id, 'check-outs')}>Saída</Button>
                                                    </Col>

                                                    <Col className="card-control-btn" span={12}>
                                                        <Button className="btn-check-in" shape="round" onClick={(e) => handleClick(e, seat.id, 'check-ins')}>Entrada</Button>
                                                    </Col>
                                                </Row>
                                            </CardList>
                                        )) : null
                                }
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </>
    );
}

export default CapacityControl;