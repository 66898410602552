import React from 'react';

import { Card } from './styles';

const CardList = ({ children, link }) => {
    return (
            <Card>
                <div className="card-list-content">
                    {children}
                </div>
            </Card>
    );
}

export default CardList;