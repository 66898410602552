import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { RightOutlined, UsergroupAddOutlined } from '@ant-design/icons';

import PageHeader from '../../../components/PageHeader';

const Administration = () => {
    const history = useHistory();

    return (
        <>
            <PageHeader title="Administração" admin onback={() => history.push('')} />

            <div className="app-wrapper">
                <Row>
                    <Col className="menu-wrapper" span={24}>
                        <Button className="custom-menu-btn" onClick={() => history.push('admin/category')}>
                            <UsergroupAddOutlined />
                            Controlar acesso
                            <RightOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Administration;