import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, List, Tooltip, Modal } from 'antd'
import { DeleteFilled, EditFilled, ExclamationOutlined, PlusOutlined, HomeFilled } from '@ant-design/icons';

import PageHeader from '../../../components/PageHeader'
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

const { confirm } = Modal

const Users = () => {
    const history = useHistory()
    const { user } = useAuth()
    const [users, setUsers] = useState([])

    useEffect(() => {
        async function fetchUsers () {
            const { data } = await api.get(`users`, {
                headers: { Authorization: `Bearer ${user?.access_token}` }
            })
            setUsers(data)
        }
        fetchUsers()
    }, [user])

    const editUser = (user) => {
        console.log(user)
        history.push('/admin/users/form')
    }

    const syncUserChurch = (user) => {
        history.push('/admin/users/church')
    }

    const showDeleteConfirm = (user) => {
        confirm({
            title: `Tem certeza que deseja deletar ${user.name}`,
            icon: <ExclamationOutlined />,
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk: () => {
                console.log('ola') //endpoint para excluir usuários
            }
        })
    }

    return (
        <>
            <PageHeader title="Usuários" admin />
            <div className="app-wrapper">
                <div className="space-30" />
                <Tooltip title="Cadastrar novo usuário">
                    <Button type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => history.push('/admin/users/form')} />
                </Tooltip>
                <div className="space-30" />
                <List
                    itemLayout="horizontal"
                    dataSource={users}
                    renderItem={user => (
                        <List.Item
                            key={user.id}
                            actions={[
                                <Button onClick={() => editUser(user)}>
                                    <EditFilled key="edit-user" />
                                    <span>Editar</span>
                                </Button>,
                                <Button onClick={() => showDeleteConfirm(user)}>
                                    <DeleteFilled key="delete-user" />
                                    <span>Deletar</span>
                                </Button>,
                                <Button onClick={() => syncUserChurch(user)}>
                                    <HomeFilled key="church-user"/>
                                    <span>Igrejas</span>
                                </Button>
                            ]}
                        >
                            <List.Item.Meta title={user.name} />
                        </List.Item>
                    )}
                />
            </div>
        </>
    );
}

export default Users;