const CpfIsValid = (cpf) => {	
    let IsValid = (cpf) => {
        if (cpf.length !== 11 || 
            cpf === "00000000000" || 
            cpf === "11111111111" || 
            cpf === "22222222222" || 
            cpf === "33333333333" || 
            cpf === "44444444444" || 
            cpf === "55555555555" || 
            cpf === "66666666666" || 
            cpf === "77777777777" || 
            cpf === "88888888888" ||
            cpf === "12345678909" || 
            cpf === "99999999999") {
            return false;
            }
        return true;
    }
    let firstValidDigit = (cpf) => {
        let sum = 0;	
        for (let i=0; i < 9; i ++) {
            sum += parseInt(cpf.charAt(i)) * (10 - i);	
        }		
        let rest = 11 - (sum % 11);	
        if (rest === 10 || rest === 11) {
            rest = 0;	
        }		
        if (rest !== parseInt(cpf.charAt(9)))	{
            return false;		
        }
        return true;
    }
    let secondValidDigit = (cpf) => {
        let sum = 0;	
        for (let i = 0; i < 10; i ++) {
            sum += parseInt(cpf.charAt(i)) * (11 - i);	
        }		
        let rest = 11 - (sum % 11);	
        if (rest === 10 || rest === 11) {
            rest = 0;	
        }	
        if (rest !== parseInt(cpf.charAt(10))) {
            return false;
        }
        return true;
    }

    if(cpf === '') return false;
    cpf = cpf.replace(/[^\d]+/g,'')
    if(!IsValid(cpf)) return false
    if(!firstValidDigit(cpf)) return false
    if(!secondValidDigit(cpf)) return false
    return true
}

export default CpfIsValid;