import React from 'react'
import { Form, Input, Button } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'

import PageHeader from '../../../../components/PageHeader'
import api from '../../../../services/api'
import { useAuth } from '../../../../hooks/auth'

const FormUsers = () => {
    const [form] = Form.useForm()
    const  {user} = useAuth()

    const validateFields = async () => {
        try {
            await form.validateFields();
        } catch (err) {
            console.log(err);
        }
    }

    const signUp = async () => {
        try {
            await api.post(`users`, {
                headers: { Authorization: `Bearer ${user?.access_token}` }
            })
        } catch (err) {
            // o que fazer
        }
    }

    return (
        <>
            <PageHeader title="Cadastro de usuários" admin />
            <div className="app-wrapper">
                <div className="space-30" />
                <div className="login-form">
                    <Form
                        layout="vertical"
                        form={form}
                        autoComplete="off"
                    >
                        <Form.Item name="name" rules={[{ required: true, message: 'Digite o nome do usuário' }]}>
                            <Input prefix={<UserOutlined />} placeholder="Nome" />
                        </Form.Item>

                        <Form.Item name="username" rules={[{ required: true, message: 'Digite o username' }]}>
                            <Input prefix={<UserOutlined />} placeholder="Usuário" />
                        </Form.Item>

                        <Form.Item name="password" rules={[{ required: true, message: 'Digite a senha' }]}>
                            <Input.Password prefix={<LockOutlined />} type="password" placeholder="Senha" />
                        </Form.Item>

                        <Form.Item
                            name="confirm-password"
                            dependencies={['password']}
                            rules={[
                                { required: true, message: 'Digite a senha novamente' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value)
                                            return Promise.resolve()
                                        return Promise.reject(new Error('As duas senhas não conferem'))
                                    }
                                })
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} type="password" placeholder="Confirmação de senha" />
                        </Form.Item>

                        <Form.Item>
                            <Button htmlType="button" onClick={validateFields} >
                                Cadastrar
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default FormUsers;