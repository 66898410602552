import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR'
import { RightOutlined } from '@ant-design/icons';

import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Loading from '../../components/Loading'

import { useLocation } from '../../hooks/location';

import './index.scss';

const MyBooking = () => {
    const { location, storage } = useLocation()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const { booking = null } = location || {}
    const { seat = null } = booking || {}
    const { scheduledService = null } = seat || {}
    const { service = null } = scheduledService || {}
    const { category, church: churches } = service
    const { city } = churches
    const { state } = city

    useEffect(() => {
        if (!location || !booking) {
            history.push('/booking-search')
        }

        setLoading(false)
    }, [history, location, booking])

    const handleCardClick = () => {
        storage({ service, churches, church: churches, booking, seats: [seat], state, city, category, hiddenButton: true })
        history.push('booking-summary')
    }

    return (
        loading ? <Loading /> :
            <>
                <PageHeader title="Reservas encontradas" />
                <div className="app-wrapper">
                    <Row className="card-list-wrapper">
                        <Col span={24}>
                            <Card onClick={handleCardClick}>
                                <div className="booking-found">
                                    <div className="card-list-container">
                                        <span className="date">
                                            {
                                                format(parseISO(service.datetime), "dd 'de' MMMM' às 'HH:mm", { locale: pt })
                                            }
                                        </span>
                                        <h1>{category.name}</h1>
                                        <span className="location">{churches.name}</span>
                                        <span className="seat-category">Lado: {seat.seatType.name}</span>
                                    </div>
                                    <RightOutlined />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
    )
}

export default MyBooking;


