import React from 'react';
import { Skeleton } from 'antd';

import PageHeader from '../PageHeader';

const Loading = () => {
    return (
        <>
            <PageHeader title="Carregando..."/>
            <div className="app-wrapper">
                <div className="loading-container">
                    <Skeleton.Avatar active shape="circle"/>
                    <Skeleton active/>
                    <Skeleton.Button active shape="round"/>
                </div>
            </div>
        </>
    );
}

export default Loading;