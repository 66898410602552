import React from 'react'

import PageHeader from '../../../components/PageHeader'
import ValidBooking from '../../../components/admin/ValidBooking'
import CheckInRegistered from '../../../components/admin/CheckInRegistered'

import { useLocation } from '../../../hooks/location'

const SearchedBooking = () => {
    const { location } = useLocation()
    const { booking } = location

    return (
        <>
            <PageHeader title="Validar reserva" admin />
            <div className="app-wrapper">
                {
                    booking ? (
                        booking.status === 1 ? <ValidBooking booking={booking} /> : <CheckInRegistered booking={booking} />
                    ) : null
                }
            </div>
        </>
    )
}

export default SearchedBooking