import React from 'react';
import { EnvironmentOutlined } from '@ant-design/icons';

const AddressContainer = ({
    streetAndNumber, 
    neighborAndCity, 
    event = "Endereço", 
    churchPin = true,
    lat = 0,
    long = 0
}) => {

    const openMaps = (lat, long) => {
        window.open("https://maps.google.com?q="+lat+","+long );
    }

    return (
        <div className="church-address border-distance">
            <div className="address-content">
                <h2>{event}</h2>
                <p>{streetAndNumber}</p>
                <p>{neighborAndCity}</p>
            </div>
            {churchPin ? 
                <div className="church-pin">
                    <EnvironmentOutlined className="open-map-btn" onClick={() => openMaps(lat, long)}/>
                </div>
            : null}
        </div>
    );
}

export default AddressContainer;